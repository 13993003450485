.image-fit-width{
    width: 100%;
}

.bg-green{
    background-color: green;
}

.bg-red{
    background-color: red;
}

.font-thin{
    font-weight: lighter;
}

.font-bold{
    font-weight: bold;
}

.text-green{
    color: green;
}

.td-img {

    width:40%;
    
}

